<template >
<div class="container-fluid">
 <div class="indicators">
        <h2>Project indicators</h2>
        <div class="graph">
            <div class="top-info"  >
                <div class="filter" style="max-width:300px; width:200px">
                    <p>Filter By</p>
                </div>
                <div class="country" >
                    <select @change="indiLoad($event)" style="max-width:200px; width:200px" id="countrySelector"  @load="helper(this)">
                        <option :value="component.id" v-for="(component) in indicator" :key="component.id" >{{component.title}}</option>
                    </select>
                </div>
                    <div class="activity" >
                    <select  @change="countryData($event)" style="max-width:500px; width:400px">
                        <option :value="graph.id" :id="titleId"  v-for="(graph) in graphDatas" :key="graph.id">{{graph.title}}</option>
                    </select>
                </div>
                <div class="malefemale" style="max-width:200px; width:200px">
                    <small class="box"></small><p>MALE</p>
                    <small class="box1"></small> <p>  FEMALE</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">

                <div class="row" style="text-align:center">
                    <div class="col-md-3">
                         <v-chart class="chart" :option="Ghana"/>
                        <h5>Ghana</h5>
                    </div>
                    <div class="col-md-3">
                         <v-chart class="chart" :option="Ethiopia" />
                          <h5>Ethiopia</h5>
                    </div>
                    <div class="col-md-3">
                         <v-chart class="chart" :option="Cameroon" />
                           <h5>Cameroon</h5>
                    </div>
                    <div class="col-md-3">
                         <v-chart class="chart" :option="Madagascar" />
                          <h5>Madagascar</h5>
                    </div>
                </div>
                </div>
            </div>
         <div class="graphImg" id="map" style="margin-top:25px">
               <v-chart class="bar" :option="graphBar" />
        </div>
        </div>
</div>
</div>
</template>

<script>

// import $ from 'jquery'
import baseUrl  from '../baseUrl'
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {GridComponent} from "echarts/components";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

export default {
 components: {
      VChart
  },
 
  provide: {
    [THEME_KEY]: "white"
  },
    data() {
        return {
            indicator:[],
            titleId:"", 
            ghanaApi:[],
            ethiopiaApi:[],
            cameroonApi:[] ,
            madagascarApi:[],
            achievedBarApi:[],
            expectedBarApi:[],
            graphDatas:[],  
            defaultGraphInfo:{},
            defaultLoad:'',
            errLog:{}

    
    };
    },

    methods: {
        defaultData(){
            baseUrl.get(`/api/getActivitiesByThemeId/1`)
                .then((res)=>{
                   
        
                     res.data.map(el=>{
                         this.graphDatas.push(el)
                       
                        this.graphDatas[0]
                      

                          this.ghanaApi = [
                            {value:this.graphDatas[0].indicators.femaleGhana, name:"Female"},
                            {value:this.graphDatas[0].indicators.maleGhana, name:"Male"}
                        ]
                           this.ethiopiaApi = [
                            {value: this.graphDatas[0].indicators.femaleEthiopia, name:"Female"},
                            {value: this.graphDatas[0].indicators.maleEthiopia, name:"Male"}
                        ]
                            this.cameroonApi = [
                                {value: this.graphDatas[0].indicators.femaleCameroon, name:"Female"},
                                {value: this.graphDatas[0].indicators.maleCameroon, name:"Male"}
                                
                            ]

                        this.madagascarApi = [
                            {value: this.graphDatas[0].indicators.femaleMadagascar, name:"Female"},
                                {value: this.graphDatas[0].indicators.maleMadagascar, name:"Male"}
                                
                            ]

                        this.achievedBarApi = [
                                {value: this.graphDatas[0].indicators.achievedGhana, name:"Male"},
                                {value: this.graphDatas[0].indicators.achievedEthiopia, name:"Female"},
                                {value: this.graphDatas[0].indicators.achievedCameroon, name:"Female"},
                                {value: this.graphDatas[0].indicators.achievedMadagascar, name:"Female"},
                        ]

                        this.expectedBarApi = [
                                {value: this.graphDatas[0].indicators.expectedGhana, name:"Male"},
                                {value: this.graphDatas[0].indicators.expectedEthiopia, name:"Female"},
                                {value: this.graphDatas[0].indicators.expectedCameroon, name:"Female"},
                                {value: this.graphDatas[0].indicators.expectedMadagascar, name:"Female"},
                        ]
                                    
                        
                    })
                    }).catch((err)=>{
                        this.errLog = err
                    })
        }, 
      
        indiLoad(id){
            baseUrl.get(`/api/getAllThemesLite`)
            .then((res)=>{
              this.indicator= res.data
               
                let uniqueData 
                res.data.some((el)=>{
                    if(el.id == id.target.value){
                        uniqueData = el
                        this.titleId = id.target.value
                        return true

                    }     
                })
            
             
                baseUrl.get(`/api/getActivitiesByThemeId/${uniqueData.id}`)
                .then((res)=>{

                        this.graphDatas = [...res.data]
                      
                      
                         this.ghanaApi = [
                            {value:this.graphDatas[2].indicators.femaleGhana, name:"Female"},
                            {value:this.graphDatas[0].indicators.maleGhana, name:"Male"}
                        ]
                           this.ethiopiaApi = [
                            {value: this.graphDatas[0].indicators.femaleEthiopia, name:"Female"},
                            {value: this.graphDatas[0].indicators.maleEthiopia, name:"Male"}
                        ]
                this.cameroonApi = [
                    {value: this.graphDatas[0].indicators.femaleCameroon, name:"Female"},
                    {value: this.graphDatas[0].indicators.maleCameroon, name:"Male"}
                    
                ]

               this.madagascarApi = [
                   {value: this.graphDatas[0].indicators.femaleMadagascar, name:"Female"},
                    {value: this.graphDatas[0].indicators.maleMadagascar, name:"Male"}
                    
                ]

              this.achievedBarApi = [
                    {value: this.graphDatas[0].indicators.achievedGhana, name:"Male"},
                    {value: this.graphDatas[0].indicators.achievedEthiopia, name:"Female"},
                    {value: this.graphDatas[0].indicators.achievedCameroon, name:"Female"},
                    {value: this.graphDatas[0].indicators.achievedMadagascar, name:"Female"},
              ]

              this.expectedBarApi = [
                    {value: this.graphDatas[0].indicators.expectedGhana, name:"Male"},
                    {value: this.graphDatas[0].indicators.expectedEthiopia, name:"Female"},
                    {value: this.graphDatas[0].indicators.expectedCameroon, name:"Female"},
                    {value: this.graphDatas[0].indicators.expectedMadagascar, name:"Female"},
              ]
                        

                        

                  
                    }).catch((err)=>{
                       
                        this.errLog = err
                    })
               
            }).catch((err)=>{
               this.errLog = err
            })

            
        },
        countryData(e){
        
             let singleGraph
              singleGraph =   this.graphDatas.filter(el=>
                    (el.id == e.target.value)
                   
                )
              let ghana =  singleGraph.find(i=>i.country.toLowerCase() == 'ghana')
          
              this.ghanaApi = [
                    {value:ghana.indicators?.femaleGhana, name:"Female"},
                    {value:ghana.indicators?.maleGhana, name:"Male"}
                ]
              

                this.ethiopiaApi = [
                      {value:ghana.indicators?.femaleEthiopia, name:"Female"},
                    {value:ghana.indicators?.maleEthiopia, name:"Male"}
                ]
                this.cameroonApi = [
                    {value:ghana.indicators?.femaleCameroon, name:"Female"},
                    {value:ghana.indicators?.maleCameroon, name:"Male"}
                    
                ]

               this.madagascarApi = [
                   {value:ghana.indicators?.femaleMadagascar, name:"Female"},
                    {value:ghana.indicators?.maleMadagascar, name:"Male"}
                    
                ]

              this.achievedBarApi = [
                    {value:ghana.indicators?.achievedGhana, name:"Male"},
                    {value:ghana.indicators?.achievedEthiopia, name:"Female"},
                    {value:ghana.indicators?.achievedCameroon, name:"Female"},
                    {value:ghana.indicators?.achievedMadagascar, name:"Female"},
              ]

              this.expectedBarApi = [
                    {value:ghana.indicators?.expectedGhana, name:"Male"},
                    {value:ghana.indicators?.expectedEthiopia, name:"Female"},
                    {value:ghana.indicators?.expectedCameroon, name:"Female"},
                    {value:ghana.indicators?.expectedMadagascar, name:"Female"},
              ]
        }
 
    },
    mounted() {
        this.indiLoad()
        this.defaultData()
    },
 
    computed:{
         graphBar() {
    //   let that = this;
                    let optionBar = {
                        legend:{
                            show:true
                        },
                 xAxis: {
                        data: [ 'Ghana', 'Ethiopia', 'Cameroon', 'Madagascar']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name:'Expected',
                        type: 'bar',
                        data:this.expectedBarApi,
                             emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                            }
                        },
                           {
                        name:'Achieved',
                        type: 'bar',
                        data:this.achievedBarApi,
                             emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                            }
                        }
                    ]
                    }
                    return optionBar;
    },

       Ghana() {
                    var colorPalette = ['#83b32a','#2e4405']
                    let ghanaPie = {
                            title: {
                        left: "center"
                        },
                        tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },
                        legend: {
                        orient: "vertical",
                        left: "left",
                         data: [
                            // "Cameroon",
                          
                        ]
                        },
                        series: [
                        {
                            name: "Ghana",
                            type: "pie",
                            radius: "55%",
                            center: ["50%", "60%"],
                            data:this.ghanaApi,
                            
                            //  [
                            //   { value: 123, name: "Male" },
                            //       { value: 12, name: "Female" },
                            //  ],
                            emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                            }
                        }
                        ],
                        color: colorPalette,
                    }
                    return ghanaPie;
    },
       Ethiopia() {
    //   let that = this;
    var colorPalette = ['#83b32a','#2e4405']
                    let ethiopiaPie = {
                           title: {
                                left: "center"
                                },
                                tooltip: {
                                trigger: "item",
                                formatter: "{a} <br/>{b} : {c} ({d}%)"
                                },
                                legend: {
                                orient: "vertical",
                                left: "left",
                                data: [
                                    // "Ethiopia",
                                
                                ]
                                },
                                series: [
                                {
                                    name: "Ethiopia",
                                    type: "pie",
                                    radius: "55%",
                                    center: ["50%", "60%"],
                                    data:this.ethiopiaApi,
                                    emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                    }
                                }
                                ],
                                 color: colorPalette,
                    }
                    return ethiopiaPie;
    },
       Cameroon() {
    //   let that = this;
    var colorPalette = ['#83b32a','#2e4405']
                    let cameroonPie = {
                            title: {
                                left: "center"
                                },
                                tooltip: {
                                trigger: "item",
                                formatter: "{a} <br/>{b} : {c} ({d}%)"
                                },
                                legend: {
                                orient: "vertical",
                                left: "left",
                                data: [
                                    // "Ethiopia",
                                    // "F",
                                    // "Ethiopia",
                                    // "Madagascar",
                                ]
                                },
                                series: [
                                {
                                    name: "Cameroon",
                                    type: "pie",
                                    radius: "55%",
                                    center: ["40%", "60%"],
                                    data:this.cameroonApi,
                                    emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                    }
                                }
                                ],
                                 color: colorPalette,
                    }
                    return cameroonPie;
    },
        Madagascar() {
    //   let that = this;
    var colorPalette = ['#83b32a','#2e4405']
                    let madagascarPie = {
                                     title: {
                                        left: "center"
                                        },
                                        tooltip: {
                                        trigger: "item",
                                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                                        },
                                        legend: {
                                        orient: "vertical",
                                        left: "left",
                                        data: [
                                            // "Ghana",
                                            // "Cameroon",
                                            // "Ethiopia",
                                            // "Madagascar",
                                        ]
                                        },
                                        series: [
                                        {
                                            name: "Madagascar",
                                            type: "pie",
                                            radius: "55%",
                                            center: ["50%", "60%"],
                                            data:this.madagascarApi,
                                            emphasis: {
                                            itemStyle: {
                                                shadowBlur: 10,
                                                shadowOffsetX: 0,
                                                shadowColor: "rgba(0, 0, 0, 0.5)"
                                            }
                                            }
                                        }
                                        ],
                                         color: colorPalette,
                    }
                    return madagascarPie;
    }
 
    }
    
}
</script>

<style scoped>
.indicators{
       background-color:#F5F7FA;
      /* display: flex; */
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 100px;
  }
  .graph{
      background-color: #fff;
      /* padding: 150px; */
      margin-top: 50px;
  }
  .top-info{
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      padding-top: 30px;
  }
  .filter{
      background-color: rgb(104, 104, 104);
      color: #fff;
      padding: 20px;
      margin-right: 10px;
      margin-left: 0px;
      width: 150px;
  }
  select{
      padding: 20px;
      margin-right: 10px;
  }
  .malefemale{
      display: flex;
      margin-left:  130px;
  }

  .box{
      height: 20px;
      width: 20px;
      background-color:#2e4405;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
  }
  .box1{
   height: 20px;
   width: 20px;
    background-color:#83b32a;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.chart {
    height: 200px;
}
.bar{
    height: 600px;
}
</style>