<template>
<div class="container">
<div class="row" id="tweets">
    <div class="col-md-4">
        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Rattan as furniture making material only came to the attention of the West from the late 1600s, as its practical advantages became apparent. <a href="https://twitter.com/hashtag/Rattan?src=hash&amp;ref_src=twsrc%5Etfw">#Rattan</a> was far stronger and more malleable than willow, cheaper and lighter.<br><br>Rattan&#39;s journey to the west 👇<a href="https://t.co/r6NFHI57BY">https://t.co/r6NFHI57BY</a> <a href="https://t.co/Lu2BVrF598">pic.twitter.com/Lu2BVrF598</a></p>&mdash; INBAR (@INBARofficial) <a href="https://twitter.com/INBARofficial/status/1435778741876236288?ref_src=twsrc%5Etfw">September 9, 2021</a></blockquote> 
    </div>

    <div class="col-md-4">
        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">5 days left to apply! <a href="https://t.co/tbfMwH5Vsx">https://t.co/tbfMwH5Vsx</a> <a href="https://t.co/NM5dNeVTK3">https://t.co/NM5dNeVTK3</a></p>&mdash; INBAR (@INBARofficial) <a href="https://twitter.com/INBARofficial/status/1436209320903938056?ref_src=twsrc%5Etfw">September 10, 2021</a></blockquote>
    </div>
<div class="col-md-4">
    <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Through the development of community-based management supported by appropriate legislation, African <a href="https://twitter.com/hashtag/rattan?src=hash&amp;ref_src=twsrc%5Etfw">#rattan</a> could provide a real opportunity for the meaningful, and sustainable, development of rural areas. 🌍<br><br>Read more 👇<a href="https://t.co/r6NFHI57BY">https://t.co/r6NFHI57BY</a><a href="https://twitter.com/hashtag/thinkrattan?src=hash&amp;ref_src=twsrc%5Etfw">#thinkrattan</a> <a href="https://t.co/q8Gf9AQ3S5">pic.twitter.com/q8Gf9AQ3S5</a></p>&mdash; INBAR (@INBARofficial) <a href="https://twitter.com/INBARofficial/status/1435196443506118662?ref_src=twsrc%5Etfw">September 7, 2021</a></blockquote> 
</div>


</div>

</div>
</template>

<script>
// import axios from 'axios'
export default {
    // data() {
    //     return {
    //         tweets:[]
    //     }
    // },
    // methods: {
    //     tweetsLoad(){
    //          axios.get('https://api.twitter.com/1.1/statuses/user_timeline.json', {
    //             params: {
    //             screen_name: "patrickmeuni"
    //             }
    //         })
    //         .then((res)=>{
    //             console.log(res)
    //            this.tweets = res.data
    //         }).catch((err)=>{
    //             console.log(err)
    //         })
    //     }
    // },
    // mounted() {
    //     this.tweetsLoad()
    // },
}
</script>

<style scoped>
#tweets{
padding-left: 100px;
padding-right: 100px;
padding-bottom: 20px;
border-radius: 50px;
}
.col-md-3{
border-radius: 50px;
position: relative;
}
small{
color: #97c149;
padding: 20px;
}
.twiter{
    position: absolute;
    bottom: 430px;
    right: 22px;
  }
.figure{
 background-color:#F5F7FA;
border-radius: 10px;
box-shadow: 0px 2px 5px 0px rgba(77,73,73,0.75);
}
.figure-caption{
padding: 20px;
border-radius: 50px;
}
/* .twitter-timeline{
    display: flex;
    justify-content: center;
    align-items: center;
} */

</style>