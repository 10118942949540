<template>
<div class="container-fluid">
    <div class="row" style="margin-top:150px">
    <div class="col-md-8"  style="margin-top:10px">
        <div class="left-image">
                     <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" >
                          <div class="carousel-inner" >
                            <div class="carousel-item"  v-for="(slider, index) in sliderImage" :key="slider.id" :class="{active:index==0}">
                              <img class="w-100 img-fluid" :src="ImgUrl+slider.imageUrl"   id="img" style="height:942px">
                            <div class="carousel-caption d-none d-md-block" id="caption">
                              <h3 class="center">
                              
                              {{slider.title}}
                                 <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" id="a" style="margin-left:20px">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"  style="padding:20px; background-color:#405e3c; border-radius:20px; box-shadow: 0px 0px 34px -9px rgba(0,0,0,0.8);"></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" id="a">
                                  <span class="carousel-control-next-icon" aria-hidden="true" style="padding:20px; background-color:#405e3c; border-radius:20px; box-shadow: 0px 0px 34px -9px rgba(0,0,0,0.8);"></span>
                                  <span class="sr-only" style="padding:100px">Next</span>
                                </a>
                              </h3>
                              
                            </div>
                            </div>
                            
                          </div>

                        </div>
        </div>
    </div>
    <div class="col-md-4" >
      <div class="right-image">
                  <div class="row">
                        <div class="col-md-12" id="col">
                                  
                                       <img  
                                            class="w-100 img-fluid"
                                             :src="ImgUrl+fPhoto.imageUrl" 
                                              style=" height:300px; margin-top:10px; margin-bottom:10px" 
                                    id="img" />
                                    <p class="house1"  style="color:#fff"  v-for="(imgSrc, i) in fPhoto" :key="`image_${i}`">{{fPhoto.title}}</p>
                                      <p class="caption1">FEATURED PHOTO</p> 
                                   
                      </div>
                      <div class="col-md-12" id="col">
                                <a href="#"  data-toggle="modal" data-target=".bd-example-modal-lg">
                                     <img
                                            class="w-100 img-fluid"
                                            src="../../public/images2/RectangleVid.jpg" 
                                            style=" height:300px; margin-top:10px; margin-bottom:10px" 
                                        id="img"/>
                                    <p class="house1"  style="color:#fff" v-for="(videSrc, i) in fVideo" :key="i">{{fVideo.title}}</p>
                                      <p class="caption1">FEATURED VIDEO</p> 
                                </a>
                      </div>
                      <div class="col-md-12" id="col">
                         <router-link :to="'/publication/' + publication.id" >
                          <img  
                                            class="w-100 img-fluid"
                                             :src='ImgUrl+publication.imageUrl' 
                                              style=" height:300px; margin-top:10px; margin-bottom:10px" 
                                    id="img" /> 
                           <p class="house1"  style="color:#fff"  v-for="(imgSrc, i) in publication" :key="`image_${i}`">{{publication.title}}</p>
                          <p class="fp" style="font-size: 12px; margin-left:10px">FEATURED PUBLICATION</p>
                         </router-link>
                      </div>
                  </div>
        </div>
  </div>
    </div>
<div class="info">
  <div class="row">
    <div class="col-md-4">
      <h4>Intra-Africa Bamboo Smallholder Farmers Livelihood Development Programme</h4><br>
       <p>Enhancing incomes, Livelihoods and climate change adaptive capacities of Africa smallholder farmers, women and youth by upscaling and increasing their participation in climate-smart bamboo value chains</p>
    </div>
<div class="col-md-8">
 <p>The Integrated Knowledge Management System is an online resource platform that collect data, information and produce knowledge through the implementation of the Inter-Africa Bamboo Smallholder Farmers Livelihood Development Programme in four countries - Cameroon, Ethiopia, Ghana and Madagascar. This system makes data, information and knowledge products accessible to all bamboo-related stakeholders and serve as a capacity strengthening platform for south-south cooperation</p>
</div>
  </div>
</div>


<!-- Modal -->

<div class="modal fade bd-example-modal-lg" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    
    <div class="modal-content">
       <div class="modal-body" id="yt-player">
               <iframe width="790" height="315" 
               :src="youtubeUrl" 
               :title="fVideo.title" 
               frameborder="0" 
               style="padding:10px" 
               class="yt_player_iframe"
               @click.self="close()" 
               id="iframeid"></iframe>
       </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import baseUrl from '../baseUrl.js'
import appConfig from "../appConfig";



export default {

    data() {
        return {
            sliderImage:[],
            fPhoto:[],
            fVideo:[],
            publication:[],
            ImgUrl: appConfig.baseUrl,
            youtubeUrl:{}
        }
    },
    methods: {
        homeLoad(){
            baseUrl.get('/api/homepage')
            .then((res)=>{
              this.sliderImage =  res.data.sliderImages
              this.fPhoto =  res.data.featuredPhoto
              this.fVideo =  res.data.featuredVideo
              this.publication = res.data.featuredPublication
             console.log(res.data.featuredVideo.videoUrl)
            this.youtubeUrl = res.data.featuredVideo.videoUrl.replace("watch?v=","embed/") 
            
            }).catch((err)=>{
                console.log(err)
            })
        }
    },

    mounted() {
        this.homeLoad()
     
         },
}
</script>

<style scoped>
/* Home css */


 #col{
    position: relative;
    text-align: center;
    color: white;
   font-size: 12px;
  }
  .house1{
    position: absolute;
    bottom: 10%;
    right: 40%;
    color: #fff;
    padding: 5px;
    text-align: center;

    background-color: rgba(32, 32, 32, 0.1); /* Black w/ opacity */
  }
#caption{
    z-index: 2;
    position: absolute;
    bottom:15%;
    right: 95%;
    padding:80px;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(137, 179, 46,0.8); /* Black w/ opacity */
    border-radius: 10px;
    font-size: 14px;
    margin: auto;
    display: block;
    width: 60%;
  }
  .caption1{
    z-index: 2;
    position: absolute;
    bottom:75%;
    right: 65%;
    color:#97c149;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin: auto;
    display: block;
  }
  #img{
    display: block;
  }
.fp{
      z-index: 2;
    position: absolute;
    bottom:75%;
    right: 60%;
    color:#97c149;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin: auto;
    display: block;
  }

.info{
padding: 100px;
}
.home{
  display: flex;
  justify-content: center;
}
#fVid:hover{
  color: white;
  padding: 16px 32px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
}

@media screen and (max-width:680px) {
  .caption1{
    font-size: 4vw;
  }
}
</style>