<template>
<div class="container-fluid">
  <div class="activity">
        <h2 style="padding: 80px;">Countries of Activity</h2>
        <div class="row">
           <div class="col-md-7">
            <div class="jvmap-smart" id="world-map">
                
            </div>
            </div>
            <div class="col-md-5">
                  <div id="home">
                      <div class="card" v-for="(com, i) in components" :key="i" style="margin:10px;" >
                        <div class="card-body">
                           <router-link :to="'/componentOne/' + com.slug" style="color:#7c7c7c"> 
                                <h5 class="card-title">{{com.title}}</h5>
                               <p class="card-text"> {{com.description}}</p>
                           </router-link>
                        </div>
                    </div>
                  </div>
                   <p>{{countryInfo.id}}</p>
               <div class="countries" style="display:none; padding:20px; box-shadow: -1px 1px 5px 1px rgba(0,0,0,0.5);" id="cont" >     
                  <div :id="countryInfo.id">
                    <div class="row" id="row">
                        <div class="col-md-8">
                          <h4 id="countryName" style="text-align:left"> {{countryInfo.country}}</h4>
                        </div>
                        <div class="col-md-4">
                            <img src="" alt="" id="countryImage">
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-md-12">
                        <p style="text-align:left" id="countryData">{{countryInfo.activityDescription}}</p>
                    </div>
                    </div>
                </div>
               
               </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import baseUrl from "../baseUrl"

window.jQuery = require('jquery');
var $ = window.jQuery;
require('jvectormap');
require('../../public/jquery-jvectormap-africa-mill.js');

export default {
   name:'Activity',

data() {
    return {
        components:[],
        countryInfo:{},
        countryCode:"",
        error:''
    }
},

   mounted(){
        this.drawMap();
        this.component();
      
    },
    methods:{

        drawMap(){
             var gdpData = {
            "ET":50.97,
            "CM":50.97,
            "GH":50.97,
            "MG":50.97
            };

            $('#world-map').vectorMap({
                map: 'africa_mill',
                backgroundColor:"#FFFFFF",
                zoomOnScroll:false,
                zoomButtons:false,
                series:{
                    regions:[{
                        values:gdpData,
                        scale:['#FFFFFF', '#98C149'],
                        normalizeFunction:'polynomial'
                    }]
                },
                regionStyle: { initial: { fill: '#DBDBDB' }, hover: { fill: '#97c149' } },
                 onRegionClick: function(event, code){
                        const fetchCountryData = (data, code)=>{
                            let result;
                            data.some(el =>{
                                if(el.country == code){
                                    result = el
                                    return true
                                }
                    
                            })
                            return result
                        }
                   
                       switch(code){
                           case 'GH':
                            this.countryCode = "Ghana"
                            break;

                            case 'CM':
                             this.countryCode = "Cameroon"
                             break;

                             case 'ET':
                              this.countryCode = "Ethiopia"
                              break;

                              case 'MG':
                               this.countryCode = "Madagascar"
                               break;
                               
                               default:
                                    this.countryCode = ""
                       }

                         baseUrl.get('/api/getCountriesOfActivity')
                            .then((res)=>{
                             
                                this.countryInfo = fetchCountryData(res.data, this.countryCode)
                                $('#countryName').text(this.countryInfo.country)
                                $('#countryData').text(this.countryInfo.activityDescription)
                                $('#countryImage').attr("src", `https://countryflagsapi.com/png/${code}`)
                            }).catch(err=>{
                                this.error = err
                            })
                    if(code == "GH"){
                        $('#ghana').show()
                        $('#cont').show()
                        $('#home').hide()
                        $('#cameroon').hide()
                        $('#mada').hide()
                        $('#eth').hide()
                    }else if(code=="CM"){
                      $('#ghana').hide()
                        $('#home').hide()
                         $('#mada').hide()
                        $('#eth').hide()
                         $('#cont').show()
                        $('#cameroon').show()
                    }else if(code=="ET"){
                      $('#ghana').hide()
                        $('#home').hide()
                         $('#mada').hide()
                        $('#eth').show()
                         $('#cont').show()
                        $('#cameroon').hide()
                    }else if(code=="MG"){
                      $('#ghana').hide()
                        $('#home').hide()
                         $('#mada').show()
                          $('#cont').show()
                        $('#eth').hide()
                        $('#cameroon').hide()
                    }else{
                        $('#home').show()
                        $('#cont').hide()
                         $('#ghana').hide()
                         $('#mada').hide()
                        $('#eth').hide()
                        $('#cameroon').hide()
                    }
                  },
            });
        },

        component(){
            baseUrl.get('/api/homepage')
                .then((res)=>{
                this.components = res.data.themes
                //sort data by accending order
                this.components.sort((a,b)=>{
                    return a.title.localeCompare(b.title)
                })

                }).catch((err)=>{
                console.log(err)
                })
        }
    }
}
</script>

<style scoped>
@import '../../public/jquery-jvectormap-2.0.5.css';

#ghana{
    display: none;
}
.activity{
    text-align: center;
}
.mapcomp{
    display: flex;
    justify-content: center;
    align-items: center;
}
#world-map{
    height: 800px;
    background-color: #DBDBDB;
}

.comp1{
    border: 1px solid rgb(160, 160, 160);
    padding: 20px;
    margin: 10px;
}
.comp1 h4{
    color: #97c149;
    padding: 10px;
}
.countries{
    max-height: 690px;
    overflow: auto;
}
#row{
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
#img{
width: 80px;
height: 80px;
object-fit: contain;
}
#countryImage{
    width:64px
}
</style>