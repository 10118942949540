<template>
  <div class="home">
    <Header></Header>
    <WelcomeHome></WelcomeHome>
    <Twitertweat></Twitertweat>
    <Indicators></Indicators>
    <Activity></Activity>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import WelcomeHome from './welcomepage';
import Twitertweat from './twitertweat';
import Indicators from './indicators';
import Activity from './activity';
import Footer from '../components/footer'


export default {
  name: 'Home',
  components: {
    WelcomeHome,
    Twitertweat,
    Header,
    Indicators,
    Activity,
   Footer
  }
}
</script>
